
import CloseIcon from "@/components/system/CloseIcon.vue";
import {leagueTableBill} from "@/views/timetable/myTimetable/LeagueTable/LeagueTable";
import {defineComponent, onMounted} from "vue";
import LeagueStar from "@/components/timetable/LeagueStar.vue";
import DwAvatar from "@/components/system/DwAvatar.vue";
import EmptyNoLesson from "@/components/timetable/EmptyNoLesson.vue";

export default defineComponent({
  name: "LeagueTable",
  components: {EmptyNoLesson, DwAvatar, LeagueStar, CloseIcon},
  data() {
    return {}
  },
  setup() {
    const {
      onGoRecord,
      init,
      refData,
    } = leagueTableBill();

    onMounted(async ()=>{
      await init();
    })

    return {
      ...refData,
      onGoRecord,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
