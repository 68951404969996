import {useRoute, useRouter} from "vue-router";
import service from "@/utils/axios";
import {reactive, toRefs} from "vue";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";

export interface ILeagueStar {
  studentId: number;            // 学生ID
  integralBalance: number;      // 学生积分余额
  studentName: string;          // 学生名称
  avatar: string;               // 学生头像
  integralCount: number;        // 当前课次积分
  sex: number;                  // 性别
}

export interface ILeagueState{
  s1: ILeagueStar[];            // 答题之星
  s2: ILeagueStar[];            // 分享之星
  rankListTop3: ILeagueStar[];  //  排名前三
  rankListTable: ILeagueStar[]; //  排名从第四开始
  rankList: ILeagueStar[];      //  排名
}

export function leagueTableBill(){
  const router = useRouter();
  const route = useRoute();

  let stateRef=  reactive(<ILeagueState>{
    s1: [],
    s2: [],
    rankListTop3: [],
    rankListTable: [],
    rankList: [],
  });

  let refData = toRefs(stateRef);



  const onGoRecord = async () =>{
    await router.push({
      name: 'SendRecord',
      query: route.query
    })
  }

  const init = async () => {
    onPageLoadingStart();
    await getStar();
    await getRank();
    onPageLoadingEnd()
  }

  const getStar = async () => {
    const s1:ILeagueStar[] = await service.get(`/dwart/an_teaching/classCourse/getAnswerOrShareStar/${route.query.scheduleId}/and/${1}`)  // 答题之星
    const s2:ILeagueStar[] = await service.get(`/dwart/an_teaching/classCourse/getAnswerOrShareStar/${route.query.scheduleId}/and/${2}`)  // 分享之星
    stateRef.s1 = s1;
    stateRef.s2 = s2;
  }

  const getRank = async () => {
    let rankList:ILeagueStar[] = await service.get(`/dwart/an_teaching/classCourse/getThislessonList/${route.query.scheduleId}`);
    stateRef.rankList = JSON.parse(JSON.stringify(rankList));
    stateRef.rankListTop3 = rankList.splice(0, 3);
    stateRef.rankListTable = rankList;
  }



  return {
    onGoRecord,
    init,
    refData,
  }
}
